/* eslint-disable @nx/enforce-module-boundaries */
import { Card } from '@mybridge/ui/card';
import { HStack, Stack } from '@mybridge/ui/layout';
import { Spinner } from '@mybridge/ui/spinner';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getAllConnectionIDs } from 'packages/mybridgev4/store/actions/connection.actions';
import {
  triggerFetchPosts,
  fetchPosts as fetchBgPosts,
} from 'packages/mybridgev4/store/actions/post.actions';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import {
  fetchCompanyPosts,
  fetchMyActivity,
  fetchPosts,
} from 'v4/lib/api/post';
import { DashboardPostBox } from './post-box';
import { PostCardSkeleton } from 'v4/components/post/skeleton';
import { flattenQueryResults, getNextPageFromURL } from 'v4/utils/utils';
import { Avatar, AvatarGroup, Button, Text } from '@mybridge/ui';
import SectionLoader from 'v4/components/common/loader/sectionLoader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ReelsBox } from '../ReelsBox';
import { MyProfileNetworkSuggestionsCarousel } from 'v4/components/public-profile/suggestions-carousel';
import _ from 'lodash';
import { ArrowUp, ArrowUp2 } from '@mybridge/icons';
import { getUserFullName, getUserProfilePic } from 'v4/lib/commons';
import { ArrowUpIcon } from '@chakra-ui/icons';

export const DashboardFeed = ({
  companyDetails,
  listtype,
  displayCount,
  location,
}) => {
  const [pages, setPages] = useState([]);
  const fetching = useRef(true);
  const tid = useRef(-1);
  const tid2 = useRef(-1);
  const observerRef = useRef();
  const [inFrame, setInFrame] = useState([]);
  const [newPostItems, setNewPostItems] = useState();
  const { loggedIn } = useSelector((state) => state.user);
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { refetchPosts, data: postDtaa } = useSelector((state) => state.posts);
  const { allConnectionIDs } = useSelector((state) => state.connection);

  const dispatch = useDispatch();
  const { ref, inView } = useInView();

  useEffect(() => {
    dispatch(getAllConnectionIDs());
  }, []);

  useEffect(() => {
    if (refetchPosts) {
      refetch?.().then(() => {
        setTimeout(() => dispatch(triggerFetchPosts(false)), 100);
      });
    }
  }, [refetchPosts]);

  useEffect(() => {
    if (loggedIn && location) {
      refetch?.();
    }
  }, [loggedIn]);

  let queryFn = fetchPosts;
  if (listtype === 'company') {
    queryFn = fetchCompanyPosts;
  } else if (listtype === 'myActivity') {
    queryFn = fetchMyActivity;
  }

  /**
   * Fetch posts as infinite list
   */
  const {
    isLoading,
    isFetching,
    data = { pages: [] },
    fetchNextPage,
    refetch,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ['dashboard-feed', companyDetails?.id, location],
    queryFn: async ({ pageParam, queryKey }) =>
      await queryFn({ pageParam, id: queryKey?.[1], location: queryKey?.[2] }),
    getNextPageParam: (lastPage, pages) => {
      return getNextPageFromURL(lastPage?.next);
    },
    refetchOnWindowFocus: false,
    cacheTime: 300000,
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(fetchBgPosts({ location }));
    }, 60000);

    // Cleanup function to clear the interval
    return () => clearInterval(intervalId);
  }, [location]);

  useEffect(() => {
    clearTimeout(tid.current);
    if (!isLoading && !isFetching) {
      tid.current = setTimeout(() => {
        fetchNextPage?.();
      }, 500);
    }
  }, [inView]);

  const filterByVisibility = (posts) => {
    const { circle, followers, following } = allConnectionIDs ?? {};
    const posts_ = [];
    for (let p of posts) {
      if (p.visibility === 0 || p?.author?.id === userProfileInfo?.id) {
        posts_?.push(p);
        continue;
      }
      const inCircle = circle?.includes?.(p?.author?.id);
      const inFollowed =
        followers?.includes?.(p?.author?.id) ||
        following?.includes?.(p?.author?.id);

      // in circle check
      if (p.visibility === 1 && inCircle) {
        posts_.push(p);
      } else if (p.visibility === 2 && (inCircle || inFollowed)) {
        posts_.push(p);
      }
    }
    return posts_;
  };
  const allPosts = flattenQueryResults(data) ?? [];
  const listingPostCount =
    data?.pages?.[data?.pageParams?.length ? data?.pageParams?.length - 1 : 0]
      ?.count ?? 0;
  const loadingPostCount = postDtaa ? postDtaa?.count : 0;

  useEffect(() => {
    const newPosts = _.differenceBy(
      _.filter(data?.pages?.[0]?.results, (post) => post.type !== 4),
      postDtaa?.results,
      'id'
    );
    // console.log('newPosts', newPosts);
    if (newPosts?.length) {
      setNewPostItems(newPosts);
    }
  }, [postDtaa]);
  // console.log(allPosts,filterByVisibility(allPosts),"djksliririri")

  const FeedLoaderButton = () => {
    return (
      <HStack
        bg="brandPrimary.500"
        borderRadius="full"
        h="48px"
        w="auto"
        maxW="200px"
        px="15px"
        cursor="pointer"
        onClick={() => {
          refetch();
          setNewPostItems(null);
          window !== undefined ? window.scrollTo(0, 0) : '';
        }}
      >
        <ArrowUpIcon color="white" />
        <AvatarGroup color="white" size="sm" max={3}>
          {newPostItems?.map((post, i) => (
            <Avatar
              key={i}
              size="sm"
              fontSize="10px"
              src={getUserProfilePic(post?.author)}
              name={getUserFullName(post?.author)}
            />
          ))}
        </AvatarGroup>
      </HStack>
    );
  };

  // console.log('feeds', allPosts)

  return (
    <>
      {isLoading ? (
        <Stack spacing={6}>
          <Card p={2}>
            <PostCardSkeleton />
          </Card>
          <Card p={2}>
            <PostCardSkeleton withImage />
          </Card>
          <Card p={2}>
            <PostCardSkeleton />
          </Card>
          <Card p={2}>
            <PostCardSkeleton />
          </Card>
        </Stack>
      ) : (
        ''
      )}
      {allPosts?.length ? (
        <Stack ref={observerRef} id="observerRef" pos="relative">
          {newPostItems && newPostItems?.length>=3 ? (
            <HStack
              h="48px"
              pos="sticky"
              mb="-60px"
              top={['10px', '230px']}
              left="0"
              right="0"
              zIndex="2"
              justifyContent="center"
            >
              <FeedLoaderButton />
            </HStack>
          ) : null}
          <InfiniteScroll
            dataLength={allPosts?.length}
            next={fetchNextPage}
            hasMore={!!hasNextPage}
            scrollThreshold={0.5}
            loader={
              <HStack p={4} justifyContent="center" w="100%">
                <Spinner />
              </HStack>
            }
            endMessage={
              <Text textAlign='center'>
                Yay! You have seen it all
              </Text>
            }
          >
            {filterByVisibility(allPosts)?.map((post, postIndex) => (
              <>
                {/* postIndex / 2 === Math.floor(postIndex / 2) && */}
                {postIndex === 2 && (
                  <ReelsBox postIndex={postIndex} isFirstReelSection={true} />
                )}
                {postIndex % 10 === 0 && postIndex !== 0 && (
                  <ReelsBox
                    postIndex={postIndex / 10}
                    isFirstReelSection={false}
                  />
                )}
                {postIndex === 9 && (
                  <MyProfileNetworkSuggestionsCarousel isFromDashbord={true} />
                )}

              <Card
                  id={`post_${postIndex}`}
                  className="dashboard-post"
                  // overflow="hidden"
                  key={postIndex}
                  variant={"thinBorderedCard"}
                  m={1}
                  mt={0}
                  mb={6}
                >
                  {/* {post?.id} */}
                  <DashboardPostBox
                    post={post}
                    isInFrame={inFrame.includes(`post_${postIndex}`)}
                    dashboardComments={true}
                  />
                </Card>
              </>
            ))}
          </InfiniteScroll>
        </Stack>
      ) : (
        ''
      )}
    </>
  );
};